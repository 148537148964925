import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Divider from "./components/Divider";
import './i18n';
import Services from "./components/Services";
import Contacts from "./components/Contacts";
import TestimonialSlider from "./components/TestimonialSlider";
import Footer from "./components/Footer";
import InfoBar from "./components/InfoBar";
import CookieConsent from "./components/CookieConsent";
import SocialLinks from "./components/SocialLinks";


const App = () => {

    //je treba vzdy upravit v social link ty topy dle popisku tam
    const showAlert = true;
    const alertMsg = (<span>Na všechny naše kurzy přispívají zdravotní pojišťovny. Pro více informací nám napište na <b>littleclubostrava@gmail.com</b></span>)


    return (
      <div>
          { showAlert ? <InfoBar text={alertMsg} messageVersion="v2409" /> : null }
          <Navbar/>
          <Hero/>
          <Divider fromColor="from-sky-200" toColor="to-white"/>
          <Services/>
          <TestimonialSlider/>
          <Divider fromColor="from-amber-100" toColor="to-white"/>
          {/*<TeamSection/>*/}
          <Contacts/>
          <Footer/>

          <SocialLinks/>
          <CookieConsent/>
      </div>


  )
}


export default App