import MultiStepForm from "./MultiStepForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import pool1 from "../img/adlandek.jpg"; // Fotka místa 1
import pool2 from "../img/vss.png"; // Fotka místa 2
import pool3 from "../img/telocvicna.jpeg"; // Fotka místa 3
import { useTranslation } from 'react-i18next';
import React, { useState} from 'react';

const Contacts = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("LittleSwim");

    // Lokality pro LittleSwim
    const locationsLittleSwim = [
        {
            name: "Vodní svět - Čapkárna",
            address: "Sokolská třída 44/2590 702 00 Ostrava - Moravská Ostrava",
            image: pool2,
            mapLink: "https://www.google.com/maps?q=Sokolská+třída+44/2590+702+00+Ostrava+-+Moravská+Ostrava",
            description: "",
        },
        {
            name: "Apartmánový dům LANDEK",
            address: "U Nemocnice 837/2 725 29 Ostrava-Petřkovice",
            image: pool1,
            mapLink: "https://www.google.com/maps?q=U+Nemocnice+837/2+725+29+Ostrava-Petřkovice",
            description: "",
        },
    ];

    // Lokality pro LittleNinjas
    const locationsLittleNinjas = [
        {
            name: "Tělocvična AD Landek",
            address: "U Nemocnice 837/2 725 29 Ostrava-Petřkovice",
            image: pool3,
            mapLink: "https://www.google.com/maps?q=U+Nemocnice+837/2+725+29+Ostrava-Petřkovice",
            description: "",
        },
    ];

    // Lokality pro LittleCare (těhotenské plavání)
    const locationsLittleCare = [
        {
            name: "Apartmánový dům LANDEK",
            address: "U Nemocnice 837/2 725 29 Ostrava-Petřkovice",
            image: pool1,
            mapLink: "https://www.google.com/maps?q=U+Nemocnice+837/2+725+29+Ostrava-Petřkovice",
            description: "",
        },
    ];

    // Výběr lokalit podle aktivní kategorie
    const locations = activeTab === "LittleSwim" ? locationsLittleSwim
        : activeTab === "LittleNinjas" ? locationsLittleNinjas
            : locationsLittleCare;

    return (
        <section id="contacts" className="container mx-auto py-8 px-6 min-h-screen pt-28 pb-28">
            <div className="flex flex-col lg:flex-row gap-10">
                {/* Místa plavání */}
                <div id="locations" className="flex-1 lg:w-1/2 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                    <h2 className="text-3xl font-bold mb-4 text-blue-900 dark:text-white text-center font-varela">
                        {activeTab === "LittleSwim" ? t('whereSwim')
                            : activeTab === "LittleNinjas" ? t('whereGym')
                                : t('whereCare')}
                    </h2>

                    {/* Přepínací tlačítka */}
                    <div className="flex flex-col sm:flex-row justify-center items-center mb-6 space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
                        <button className={`px-4 py-2 text-xl font-gluten ${activeTab === "LittleSwim" ? "text-blue-500" : "text-gray-300"} transition-all duration-300`} onClick={() => setActiveTab("LittleSwim")}>
                            LittleSwim
                        </button>
                        <button className={`px-4 py-2 text-xl font-gluten ${activeTab === "LittleNinjas" ? "text-amber-500" : "text-gray-300"} transition-all duration-300`} onClick={() => setActiveTab("LittleNinjas")}>
                            LittleNinjas
                        </button>
                        <button className={`px-4 py-2 text-xl font-gluten ${activeTab === "LittleCare" ? "text-pink-500" : "text-gray-300"} transition-all duration-300`} onClick={() => setActiveTab("LittleCare")}>
                            LittleCare
                        </button>
                    </div>

                    {/* Seznam míst podle vybrané sekce */}
                    <div className="flex flex-col space-y-6">
                        {locations.map((location, index) => (
                            <div
                                key={index}
                                className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out"
                            >
                                <img
                                    src={location.image}
                                    alt={location.name}
                                    className="w-full h-36 object-cover rounded-lg mb-4"
                                />
                                <h3 className="text-xl font-semibold text-blue-900 dark:text-white mb-2">
                                    {location.name}
                                </h3>
                                <a
                                    href={location.mapLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-lg text-gray-700 dark:text-gray-300 mb-2 flex items-center hover:underline"
                                >
                                    <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        className="w-5 h-5 text-blue-500 dark:text-blue-400 mr-2"
                                    />
                                    {location.address}
                                </a>
                                <p className="text-gray-600 dark:text-gray-400">{location.description}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Přihláška na lekci */}
                <div id="reservation" className="flex-1 lg:w-1/2 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                    <h2 className="text-3xl font-bold mb-4 text-blue-900 dark:text-white text-center font-varela">
                        {t('contactFormTitle')}
                    </h2>
                    <MultiStepForm/>
                </div>
            </div>
        </section>
    );
};

export default Contacts;