import { useTranslation } from 'react-i18next';
import { Zoom } from "react-awesome-reveal";
import WavyBackground from "./WavyBackground";
import { useState } from 'react';

const Hero = () => {
    const { t } = useTranslation();

    const services = [
        {
            id: 'littleSwim',
            color: 'bg-sky-300 hover:bg-sky-400',
            title: t('littleSwimTitle'),
            desc: t('littleSwimDescription'),
            link: '#plavaniprodeti'
        },
        {
            id: 'littleNinjas',
            color: 'bg-amber-200 hover:bg-amber-300',
            title: t('littleNinjasTitle'),
            desc: t('littleNinjasDescription'),
            link: '#cviceniprodeti'
        },
        {
            id: 'littleCare',
            color: 'bg-[#FFCCE1] hover:bg-pink-300',
            title: t('littleCareTitle'),
            desc: t('littleCareDescription'),
            link: '#plavaniprotehotnemaminky'
        }
    ];

    return (
        <div id="home" className="hero min-h-screen flex flex-col justify-center items-center bg-cover bg-center relative overflow-hidden bg-gradient-to-b from-white to-sky-200 dark:bg-gradient-to-b dark:from-gray-900 dark:to-blue-900">
            <WavyBackground colors={["#6fc3e8", "#26A5E3", "#C7EAFE"]} waveWidth={30}>
                <Zoom triggerOnce={true}>
                    <div className="text-center max-w-4xl mx-auto pt-20 sm:pt-0 md:pt-28">
                        <h1 className="font-bold font-gluten dark:text-white text-[clamp(1.8rem,4vw,3.5rem)] leading-tight">
                            <span className="text-3xl sm:text-4xl md:text-6xl lg:text-6xl block pb-1">{t('welcomeMessage')}</span>
                            <span className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl text-blue-900 dark:text-white">Little</span>
                            <span className="font-kalam text-[clamp(1.5rem,3vw,2.8rem)] text-transparent bg-clip-text bg-gradient-to-r from-sky-400 to-sky-600 dark:from-blue-400 dark:to-blue-600 inline-block transform skew-y-[-10deg]">Club</span>
                        </h1>
                        <p className="font-gluten text-[clamp(1rem,2vw,1.3rem)] text-gray-600 dark:text-white mt-2">{t('descMessage')}</p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8 w-full max-w-5xl mx-auto px-4 sm:pb-40">
                        {services.map(service => (
                            <a key={service.id} href={service.link} className={`block p-6 rounded-full shadow-lg transition-transform transform hover:scale-105 ${service.color}`}>
                                <h2 className="text-lg md:text-xl font-semibold font-gluten text-center">{service.title}</h2>
                                <p className="text-sm sm:text-base md:text-lg font-varela text-center mt-2">{service.desc}</p>
                            </a>
                        ))}
                    </div>
                </Zoom>
            </WavyBackground>
        </div>
    );
};

export default Hero;
